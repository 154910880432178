/*
 * Reset some basic elements
 */


// VARIABLES 

// colors 
// $color-bg: #E4E8E7;
$color-news-title: #FF5A6E;
$color-news-border-description: #D7D7D7;
$color-title-project: #529D2E;
$color-font-default: #4e565F;
// $color-green-table-gbif: rgb(82, 157, 46);
$color-green-table-gbif: $primary;

// $white: #fff;
$white: #f2f2f2;
$color-bg: $white;

$color-menu: #005819;

$menu-bg: #00ac34;

$green-100: #D7E9D7;
$green-200: #bcdabc;

$darky: darkslategray;
// $caption-bg: $menu-bg;
$caption-bg: $darky;

// BASIC ELEMENTS

body{
    background-color: $color-bg;
    color: $color-font-default;
}

h2{
    text-align: center;
    color: $color-title-project;
}

h2, h3 {
    text-align: center;
    color: $color-title-project;
    margin: 30px 0;
}

h4 {
    text-align: left;
    // margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    // border-bottom: 1px solid $color-title-project
}

a {
    color: $color-menu;
}

/* navbar  */

.nav-item > a, .nav-item > a:hover{
    color: $white;
}

.navbar, .navbar .container {
    background-color: $menu-bg;
}

.navbar-toggler-icon {
    background-image: escape-svg($navbar-light-toggler-icon-bg);
  }

.navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
}

.header-la-logo {
    padding: 14px 0 5px 0px;
}

.header-la-desc {
    padding-bottom: 12px;
    font-family: 'Signika', sans-serif;
    font-weight: 400;
    font-size: 26px;
}

.nav-item:not(:first-child) {
    padding: 0px 10px;
}

.container{
    // background-color: $white;
    // padding: 1% 3% 1% 3%;
}

h5{
    text-align: center;
    color: $color-title-project;
}

.table .thead-light th{
    // background-color: $color-green-table-gbif;
    // color: $white;
}

.page-content {
      margin-top: 30px;
      margin-bottom: 30px;
}

// HEADER
.site-header, .site-header .container {
    // background-color: #d4e7cc;
    background-color: $white;
}

// HOME

@include media-breakpoint-up(md) {
  .card-styles {
    margin-top: -40px; } }

@include media-breakpoint-down(md) {
    .card {
        margin-top: 20px; }

    #cards > div > div > div:nth-child(3)
    { margin-bottom: 20px; }
}

#cards .card {
  border: none;
  -webkit-box-shadow: 0px 11px 30px -9px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 11px 30px -9px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 11px 30px -9px rgba(0, 0, 0, 0.05); }
  #cards .card .card-header {
    border: none;
    background: #343a40;
    color: #fff; }

#cards .icon-wrap {
  position: relative; }
#cards .icon-wrap .icon {
    width: 60px;
    height: 60px;
}

#cards .icon-wrap .icon span {
      font-size: 20px;
}

#cards .icon-wrap .icon.bg-info {
      border: 5px solid #92dcfe;
}

#cards .icon-wrap .icon.bg-success {
      border: 5px solid #baf2ce;
}

#cards .icon-wrap .icon.bg-warning {
      border: 5px solid #ffefd6;
}

#cards i {
      font-size: 1.5em;
      color: $white;
}

h5.card-title {
      text-align: left;
}

.section-2 {
      background-color: $green-100;
      min-height: 400px;
}

.carousel-caption > h4,
.carousel-caption > h5 {
    color: $white,
}

.carousel-caption {
    color: $white;
    background-color: $caption-bg;
    opacity: .8;
    right: 0%;
    left: 0%;
    padding-top: 10px;
    padding-bottom: 0px;
    margin: 0 0 100px 0;
}

#la-partipants-carousel {
    margin-top: -30px;
}

#la-partipants-carousel > div > div > div > div > div > div.img-box > div > div,
#la-partipants-carousel > div.carousel-inner > div > div.row > div.col {
    padding: 0px !important;
}

div.carousel-inner {}

ol.carousel-indicators li {
  }

#la-partipants-carousel > div > div > div > div > div > div > div > div > img
{
    display: block;
    object-fit: cover;
    object-position: 100% 0;
    height: 400px !important;
}

#communityAction > div > div > img {
    min-height: 600px !important;
}

#la-partipants-carousel > div > div > div > div > div > div > div > div > img.img-first {
    object-position: 50% 50%;
}

ol.carousel-indicators li.active {
      background-color: $white;
}

.carousel {
}

.carousel .carousel-item {
    text-align: center;
    overflow: hidden;
}

.carousel .carousel-item h4 {
    border-bottom: 0px;
}

.carousel .carousel-item img {
    max-width: 100%;
    display: inline-block;
}

.carousel .carousel-item .btn {
    padding: 6px 15px;
    margin-top: 5px;
}

.carousel .carousel-item .btn:hover {
}

.carousel .carousel-item .btn i {
    font-size: 14px;
    // font-weight: bold;
    margin-left: 5px;
}

.carousel .thumb-wrapper {
    text-align: left;
    background: $caption-bg;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.1);
}

.carousel .thumb-content,
.carousel .thumb-content .container {
    padding: 15px;
    color: $white;
    background: $caption-bg;
}

.carousel-control-prev, .carousel-control-next {
    width: 34px;
    background: none;
    align-self: flex-end;
    color: $green-200;
    font-size: 60px;
}

.carousel-control-prev i, .carousel-control-next i {
    font-size: 46px;
    position: absolute;
    bottom: 10%;
    display: inline-block;
    z-index: 5;
}

.carousel-control-prev > i {
    margin-left: 20px;
}

@include media-breakpoint-down(sm) {
    .carousel-control-prev i, .carousel-control-next i {
        display: none;
    }
}

.carousel-control-next > i {
    margin-right: 20px;
}

.carousel-indicators {
    bottom: 130px !important;
}

@include media-breakpoint-down(sm) {
    .carousel-indicators {
        display: none;
    }
}

.carousel-indicators li, .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 4px;
    border: none;
}

.carousel-indicators li {
    background: #FFF;
}

.carousel-indicators li.active {
    background: $white;
  }

.carousel-lazy-image {
      background: url("/assets/img/loader.gif") center center no-repeat;
  }

// PAGE NEWS
#container-news {
    background-color: $color-bg;
}

.news{
    background-color: $white;
    border: 1px solid #1B1B1B;
}

.news > .col > h3 {
    border-bottom: 1px solid $color-news-border-description;
    padding: 1%;
    text-align: left;
}

.news > .col > h3 > a {
    color: $color-title-project;
    text-decoration: none;
}

.news > h3 > a:hover{
    text-decoration: none;
}

.short-description{
    padding: 1%;
}

.published-by{
    border-top: 1px solid $color-news-border-description;
    font-style: italic;
    padding: 1%;
}

// PAGE ABOUT

.tab-content h4 {
    text-align: left;
    // margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    border-bottom: 1px solid $color-title-project
}

#pills-coordinators li{
    list-style-type: lower-alpha;
}


// PAGE SINGLE NEWS
#container-single-news img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#container-single-project h5{
    text-align: left;
}

// PAGE PARTICIPANT
#container-participant img{
    height: auto; 
    width: auto; 
    max-width: 100%; 
    align-content: center;
    display: flex;
    padding-top:1%;
}

// HEADER

.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    background-color: $color-menu;
}


.dropdown-item:hover{
    background-color: $color-green-table-gbif;
}

// HOME PAGE

.col-index{
    display: flex;
    justify-content: center;
}

.feature{
    flex: 0 0 95%;
    max-width: 100%;
    margin: 12px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    background: #fff;
}

.feature-highlight{
    max-width: 100%;
    margin: 12px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    background: #fff;
}

.feature-highlight .feature-content{
    padding: 24px 24px 48px 24px;
}

.feature .feature-content{
    padding: 24px 24px 48px 24px;
}

.feature-date {
    font-weight: 500;
    font-size: 90%;
}

// FOOTER

.logo-footer{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.twitter-follow{
    margin-right: 2%;
    margin-top: 2%;
}


.participant-email-icon {
    font-size: 20px;
}

// On tables
.column-centered {
    text-align: center;
    vertical-align: middle;
}

// Status
//
.participant-status {
    text-shadow: 0px 0px 0px #607D8B;
    font-size: 12px;
}

.participant-status-under-development,
.participant-status-inactive {
    color: #607D8B;
}

.participant-status-service-degradation,
.participant-status-degraded {
    color: #FBC02D;
}

.participant-status-offline {
    color: #e57373;
}

.participant-status-live,
.participant-status-online {
    color: #66bb6a;
}

.btn-bluesky-compact {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background-color: #1e90ff;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  transition: background-color 0.3s;
}

.btn-bluesky-compact:hover {
  background-color: #1c86ee;
  color: white !important;
  text-decoration: none;
}

.btn-bluesky-compact .icon {
  width: 16px;
  height: 16px;
  fill: white;
}


.btn-bluesky {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #1e90ff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.btn-bluesky:hover {
  color: white !important;
  text-decoration: none;
}

.btn-bluesky .icon {
  width: 20px;
  height: 20px;
  fill: white;
}

