table {
    width: 100%;
    max-width: 100%;
    margin-bottom: $spacer;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.

    th,
    td {
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: $table-border-width solid $table-border-color;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody + tbody {
        border-top: (2 * $table-border-width) solid $table-border-color;
    }

    .table {
        background-color: $body-bg;
    }
}
